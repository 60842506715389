'use client';

import { DetectWebinappContext } from './use-detect-webinapp';

export interface DetectWebinappProviderProps {
  children: React.ReactNode;
  isWebinapp?: boolean;
}

export function DetectWebinappProvider({
  children,
  isWebinapp = false,
}: DetectWebinappProviderProps) {
  return (
    <DetectWebinappContext.Provider value={isWebinapp}>
      <>{children}</>
    </DetectWebinappContext.Provider>
  );
}
