'use client';

import { Button } from '@ahafood/ui/components/button';
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from '@ahafood/ui/components/drawer';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@ahafood/ui/components/popover';
import { assetPrefix } from '@ahm/api-wrappers-core';
import { cn, session } from '@ahm/common-helpers';
import { X } from 'lucide-react';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import RestaurantRegistrationForm from './store-contact';
import { useIsMobile } from '@/lib/is-mobile';

export default function ContactFormButton() {
  const [showButton, setShowButton] = useState(false);
  const isMobile = useIsMobile();
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

  useEffect(() => {
    setShowButton(session.getItem('acquire_visited') !== 'true');
  }, []);

  if (!showButton) {
    return null;
  }

  const handleHideAcquireForm = () => {
    session.setItem('acquire_visited', 'true');
    setShowButton(false);
    setIsOpenForm(false);
  };

  const handleToggleForm = () => {
    setIsOpenForm((prev) => !prev);
  };

  if (isMobile) {
    return (
      <Drawer open={isOpenForm} onOpenChange={setIsOpenForm}>
        <DrawerTrigger asChild>
          <FormTrigger
            onClose={handleHideAcquireForm}
            className={cn({
              'opacity-0': isOpenForm,
            })}
          />
        </DrawerTrigger>
        <DrawerContent className="p-0 bg-muted-foreground border-0 max-h-[90%] overflow-hidden bg-gradient-to-r from-secondary-70 to-secondary-50">
          <div className="relative aspect-square -mb-28 size-80 mx-auto">
            <Image
              src={`${assetPrefix}/static/images/contact-robot.webp`}
              alt="contact-icon"
              fill
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
          </div>
          <RestaurantRegistrationForm className="bg-transparent" />
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Popover open={isOpenForm} onOpenChange={setIsOpenForm}>
      <PopoverTrigger asChild>
        <FormTrigger
          onClose={handleHideAcquireForm}
          className={cn({
            'opacity-0': isOpenForm,
          })}
        />
      </PopoverTrigger>
      <PopoverContent
        className="p-0 aspect-auto border-none bg-transparent w-full max-w-sm relative shadow-none"
        side="top"
        sideOffset={-120}
        align="end"
      >
        <div className="relative aspect-square -mb-28 size-80 mx-auto">
          <Image
            src={`${assetPrefix}/static/images/contact-robot.webp`}
            alt="contact-icon"
            fill
            style={{ objectFit: 'cover', objectPosition: 'center' }}
          />
        </div>
        <div className="relative">
          <Button
            size="icon"
            className="absolute top-2 right-2 z-10 [&_svg]:size-6 size-8 text-background hover:text-background hover:bg-neutral-90/50"
            variant="ghost"
            onClick={handleToggleForm}
          >
            <X />
          </Button>

          <RestaurantRegistrationForm dir="vertical" />
        </div>
      </PopoverContent>
    </Popover>
  );
}

const FormTrigger = ({ onClick, onClose, className, ...props }: any) => {
  return (
    <div className={cn('fixed bottom-4 right-4 z-30', className)} {...props}>
      <Button
        className="absolute top-0 right-0 z-10 bg-neutral-50 hover:bg-neutral-40 rounded-full size-4"
        onClick={onClose}
        variant="ghost"
        size="icon"
      >
        <X className="size-5 text-white" />
      </Button>
      <button className="items-center flex flex-col" onClick={onClick}>
        <div className="relative aspect-square -mb-10 size-24">
          <Image
            src={`${assetPrefix}/static/images/contact-robot.webp`}
            alt="contact-icon"
            fill
            style={{ objectFit: 'cover', objectPosition: 'center' }}
          />
        </div>
        <div className="bg-gradient-to-r from-secondary-70 to-secondary-50 z-[51] py-2 text-center font-bold px-4 rounded-md text-white">
          Tư vấn
          <br />
          Đăng ký
        </div>
      </button>
    </div>
  );
};
